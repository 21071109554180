import Oidc, { UserManagerSettings, WebStorageStateStore } from 'oidc-client';
import { envVars } from '../agnosticUtilities/reactEnvVars';
import { myLogger, myLoggerLevel } from '../agnosticUtilities/myLogger';
import { IFrontlineOidcUser } from './oidcTypes';
import { LogLevelDesc } from 'loglevel';
import { assertUnreachable } from '../agnosticUtilities/neverCheckers';

export interface IFlIdmUserManager extends Oidc.UserManager {
	/**
	 * syntactic sugar over getUser so you know what IDM tends to send back
	 */
	getFlUser: () => Promise<IFrontlineOidcUser | null>;
}

export const AUTH_CALLBACK_ROUTE_NAME = 'auth_callback';

export const makeOidcUserManager = (): IFlIdmUserManager => {
	///////////////////////////////
	// OidcClient config
	///////////////////////////////
	Oidc.Log.logger = console;
	Oidc.Log.level = pinoLevelToOIDCLogLevel(myLoggerLevel);

	const customWebStateStore = new WebStorageStateStore({
		prefix: 'oidc.',
		store: createdCommentedSessionStore(),
	});

	const settings: UserManagerSettings = {
		authority: envVars.get('ID_GATEWAY_URL'),
		// eslint-disable-next-line @typescript-eslint/camelcase
		client_id: envVars.get('IDM_CLIENT_ID'),
		// eslint-disable-next-line @typescript-eslint/camelcase
		redirect_uri: `${window.location.origin}/${AUTH_CALLBACK_ROUTE_NAME}`,
		// eslint-disable-next-line @typescript-eslint/camelcase
		post_logout_redirect_uri: `${window.location.origin}/?`,
		// eslint-disable-next-line @typescript-eslint/camelcase
		response_type: 'id_token token',
		scope: 'openid flapi.public profile',
		filterProtocolClaims: true,
		loadUserInfo: true,
		userStore: customWebStateStore,
	};
	const userManager = new Oidc.UserManager(settings);

	return Object.assign(userManager, {
		getFlUser: userManager.getUser,
	});
};

interface IAlreadySignedIn {
	user: IFrontlineOidcUser;
	currentlyInRedirect: false;
}

interface IInRedirect {
	currentlyInRedirect: true;
}

const USER_STORAGE_OVERRIDE_KEY = 'USER_STORAGE_OVERRIDE_KEY';

/**
 * Allows a way for developers to override the user and skip the login
 * Note that this is still secure because "bad actors" would still need to be sending in a valid bearer token.
 * So, this is still very safe
 */
const getUserOverrideFromLocalStorage = (): IFrontlineOidcUser | null => {
	function shortCircuit(reason: string): null {
		const EJECT_MSG = "So, we won't be overriding the user from localstorage. Please set a valid object first.";
		myLogger.error(`Tried loading user from localstorage.getItem('${USER_STORAGE_OVERRIDE_KEY}') but ${reason}. ${EJECT_MSG}`);
		removeUserOverride();
		return null;
	}

	const stringifiedUser = localStorage.getItem(USER_STORAGE_OVERRIDE_KEY);
	if (!stringifiedUser) {
		return null;
	}
	const hopefullyAValidUserObj = JSON.parse(stringifiedUser) as IFrontlineOidcUser;
	// While we're trusting the dev to have submitted good JSON, we do some runtime validation for ease of use
	if (!hopefullyAValidUserObj.access_token) {
		return shortCircuit('.access_token is missing');
	}
	if (!hopefullyAValidUserObj.profile) {
		return shortCircuit('.profile is missing');
	}
	if (!hopefullyAValidUserObj.profile.sub) {
		return shortCircuit('.profile.sub is missing');
	}
	if (!hopefullyAValidUserObj.profile.organizationid) {
		return shortCircuit('.profile.organizationid is missing');
	}
	return hopefullyAValidUserObj;
};

export const removeUserOverride = (): void => {
	localStorage.removeItem(USER_STORAGE_OVERRIDE_KEY);
};

export const getUserOrRedirectToLogin = async (userManager: IFlIdmUserManager): Promise<IAlreadySignedIn | IInRedirect> => {
	// First, allow developers to mock the user via sessionstate. This enables Cypress testing
	const overridenUser = getUserOverrideFromLocalStorage();
	if (overridenUser) {
		myLogger.warn('WARNING: You are currently mocking the user instead of requiring a login. This might cause strange results.');
		return {
			currentlyInRedirect: false,
			user: overridenUser,
		};
	}

	// Try to get the real thing now
	const user = await userManager.getFlUser();

	if (user) {
		return {
			currentlyInRedirect: false,
			user,
		};
	} else {
		// Then redirect to the signin page so we can get the user next time
		const signinRequest = await userManager.createSigninRequest();
		window.location.href = signinRequest.url;
		return {
			currentlyInRedirect: true,
		};
	}
};

const pinoLevelToOIDCLogLevel = (pinoLevel: LogLevelDesc): number => {
	if (pinoLevel === 'DEBUG' || pinoLevel === 'debug' || pinoLevel === 'TRACE' || pinoLevel === 'trace') {
		return Oidc.Log.DEBUG;
	} else if (pinoLevel === 'WARN' || pinoLevel === 'warn') {
		return Oidc.Log.WARN;
	} else if (pinoLevel === 'INFO' || pinoLevel === 'info') {
		return Oidc.Log.INFO;
	} else if (pinoLevel === 'ERROR' || pinoLevel === 'error') {
		return Oidc.Log.ERROR;
	} else if (pinoLevel === 0 || pinoLevel === 1 || pinoLevel === 2 || pinoLevel === 3 || pinoLevel === 4 || pinoLevel === 5) {
		throw new Error("numberical log levels are not supported since we coudln't find a proper mapping");
	} else if (pinoLevel === 'SILENT' || pinoLevel === 'silent') {
		return Oidc.Log.NONE;
	} else {
		return assertUnreachable(pinoLevel);
	}
};

const createdCommentedSessionStore = () => {
	const storageOfChoice = window.sessionStorage;
	const debuggedStorage: Storage = {
		clear: function() {
			myLogger.debug(' >> About to call clear');
			storageOfChoice.clear();
		},
		getItem: function(key) {
			myLogger.debug(` >> About to getItem for key ${key}`);
			const value = storageOfChoice.getItem(key);
			myLogger.debug(`  >>> the value was: ${value}`);
			return value;
		},
		removeItem: function(key) {
			myLogger.debug(` >> About to removeItem for key ${key}`);
			return storageOfChoice.removeItem(key);
		},
		setItem: function(key, value) {
			myLogger.debug(` >> about to setItem with key ${key} to value ${value}`);
			return storageOfChoice.setItem(key, value);
		},
		get length() {
			return storageOfChoice.length;
		},
		get key() {
			return storageOfChoice.key;
		},
	};
	return debuggedStorage;
};
