import React, { Fragment } from 'react';
import { Layout, Divider } from 'antd';
import { cx, css } from 'emotion';
import { linedBoxCss } from '../sharedStyles/boxes';

const { Content } = Layout;

const contentBoxPadding = 24;

export class ContentBox extends React.Component<{ children: JSX.Element[] }> {
	public render() {
		const mainContentCss = cx(
			linedBoxCss,
			css({
				margin: '24px 16px',
				padding: contentBoxPadding,
				background: '#fff',
				minHeight: 280,
			}),
		);

		return (
			<div
				style={{
					maxWidth: 800,
				}}
			>
				<Content className={mainContentCss}>{this.props.children}</Content>
			</div>
		);
	}
}

export const ContentTitle = (props: { title: string }) => {
	const dividerExtraWidthStyle = css({
		width: `calc(100% + ${contentBoxPadding * 2}px)`,
		position: 'relative',
		left: -contentBoxPadding,
	});

	return (
		<Fragment>
			<h2>{props.title}</h2>
			<Divider className={cx(dividerExtraWidthStyle)} />
		</Fragment>
	);
};
