import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { myLogger } from './myLogger';
import { defaultErrorHandler } from './myHttpErrorTranslators';
import { stringifyAllJodaValues } from './jodaSerializer';
import { IRequestOptions } from './IRequestOptions';

// axios.defaults.timeout = 25000;

/*
// For some reason interceptors are tricky, so use them wisely. Use jshttp/http-errors instead
axios.interceptors.request.use(request => {
  console.error("*****", request);
  return request;
})
*/

const createAxiosConfig = (requestOptions: IRequestOptions, authToken: INeedAtLeastOneToken): AxiosRequestConfig => {
	const axiosRequestConfig = requestOptions.requestConfig || {};
	const existingHeaders = axiosRequestConfig.headers as Record<string, string>;
	const newHeaders: Record<string, string> = Object.assign({}, existingHeaders, {
		'X-FL-Hop-CorrelationId': requestOptions.correlationId,
	});
	if (authToken.aesopToken) {
		newHeaders.AesopToken = authToken.aesopToken;
	}
	if (authToken.idmBearerToken) {
		newHeaders.Authorization = `Bearer ${authToken.idmBearerToken}`;
	}

	const newAxiosRequestConfig: AxiosRequestConfig = Object.assign({}, axiosRequestConfig, {
		headers: newHeaders,
	});

	// Handle any types that are not parsed automatically
	if (requestOptions.requestConfig && requestOptions.requestConfig.params) {
		const paramsDictionary = requestOptions.requestConfig.params as Record<string, unknown>;

		newAxiosRequestConfig.params = stringifyAllJodaValues(paramsDictionary);
	}

	return newAxiosRequestConfig;
};

interface IRequestOptionsWithBody extends IRequestOptions {
	/** the request body payload that you will be sending to the server */
	body: object & unknown;
}

interface IRequireBearerToken {
	idmBearerToken: string;
	aesopToken?: string | undefined;
}

interface IRequireAesopToken {
	idmBearerToken?: string | undefined;
	aesopToken: string;
}

/** Must have at least one of the auth token types */
type INeedAtLeastOneToken = IRequireBearerToken | IRequireAesopToken;

type IMyHttpFunc = <TReturnType>(requestOptions: IRequestOptions, authToken: INeedAtLeastOneToken) => Promise<AxiosResponse<TReturnType>>;
type IMyHttpFuncWithRequiredBody = <TReturnType>(requestOptions: IRequestOptionsWithBody, authToken: INeedAtLeastOneToken) => Promise<AxiosResponse<TReturnType>>;

/**
 * Methods for delete, get, post and put from upstream services.  Automatically handles logging and error handling.
 * If an error <500 happens will will reject with an appropriate error from the http-errors library.  If an error > 500
 * happens it will reject with a 502.
 *
 * Headers for access token and fl-hop-correlationid are automatically applied.
 *
 * @param {string} idmBearerToken
 * @param {any} [body] - request body, only applied on POST/PUT
 * @param {AxiosRequestConfig} [requestConfig={}] - an axios request configuration
 * @param {string} correlationId - a GUID used to combine multiple logs from various services together in the logs
 * @param {string} serviceName
 * @param {string} url
 * @returns {Promise<any>}
 */
interface IMyHttpClient {
	del: IMyHttpFunc;
	get: IMyHttpFunc;
	post: IMyHttpFuncWithRequiredBody;
	put: IMyHttpFuncWithRequiredBody;
	patch: IMyHttpFuncWithRequiredBody;
}

function commonPreLogger(requestOptions: IRequestOptions): void {
	const { requestConfig = {}, correlationId, serviceName, url } = requestOptions;
	myLogger.debug({ url, params: requestConfig.params, correlationId }, `Attempting service call to ${serviceName || url}`);
	myLogger.trace({
		logMessage: `This object contains the full request of service call to ${serviceName || url}`,
		fullRequest: requestOptions,
	});
}

const get = async <TReturnType>(requestOptions: IRequestOptions, authToken: INeedAtLeastOneToken): Promise<AxiosResponse<TReturnType>> => {
	commonPreLogger(requestOptions);
	const configWithReqHeaders = createAxiosConfig(requestOptions, authToken);
	try {
		const result = await axios.get<TReturnType>(requestOptions.url, configWithReqHeaders);
		myLogger.trace(result);
		return result;
	} catch (err) {
		// tslint:disable-next-line:no-unsafe-any // Note: tslint has a longstanding bug regarding err being considered any
		return defaultErrorHandler(err, requestOptions);
	}
};

const post = async <TReturnType>(requestOptions: IRequestOptionsWithBody, authToken: INeedAtLeastOneToken): Promise<AxiosResponse<TReturnType>> => {
	commonPreLogger(requestOptions);
	const configWithReqHeaders = createAxiosConfig(requestOptions, authToken);
	try {
		const result = await axios.post<TReturnType>(requestOptions.url, requestOptions.body, configWithReqHeaders);
		myLogger.trace(result);
		return result;
	} catch (err) {
		// tslint:disable-next-line:no-unsafe-any // Note: tslint has a longstanding bug regarding err being considered any
		return defaultErrorHandler(err, requestOptions);
	}
};

const patch = async <TReturnType>(requestOptions: IRequestOptionsWithBody, authToken: INeedAtLeastOneToken): Promise<AxiosResponse<TReturnType>> => {
	commonPreLogger(requestOptions);
	const configWithReqHeaders = createAxiosConfig(requestOptions, authToken);
	try {
		const result = await axios.patch<TReturnType>(requestOptions.url, configWithReqHeaders);
		myLogger.trace(result);
		return result;
	} catch (err) {
		// tslint:disable-next-line:no-unsafe-any // Note: tslint has a longstanding bug regarding err being considered any
		return defaultErrorHandler(err, requestOptions);
	}
};

const put = async <TReturnType>(requestOptions: IRequestOptionsWithBody, authToken: INeedAtLeastOneToken): Promise<AxiosResponse<TReturnType>> => {
	commonPreLogger(requestOptions);
	const configWithReqHeaders = createAxiosConfig(requestOptions, authToken);
	try {
		const result = await axios.put<TReturnType>(requestOptions.url, configWithReqHeaders);
		myLogger.trace(result);
		return result;
	} catch (err) {
		// tslint:disable-next-line:no-unsafe-any // Note: tslint has a longstanding bug regarding err being considered any
		return defaultErrorHandler(err, requestOptions);
	}
};

const del = async <TReturnType>(requestOptions: IRequestOptions, authToken: INeedAtLeastOneToken): Promise<AxiosResponse<TReturnType>> => {
	commonPreLogger(requestOptions);
	const configWithReqHeaders = createAxiosConfig(requestOptions, authToken);
	try {
		const deleteResponse: AxiosResponse<TReturnType> = await axios.delete(requestOptions.url, configWithReqHeaders);
		myLogger.trace(deleteResponse);
		return deleteResponse;
	} catch (err) {
		// tslint:disable-next-line:no-unsafe-any // Note: tslint has a longstanding bug regarding err being considered any
		return defaultErrorHandler(err, requestOptions);
	}
};

function generateUUID() {
	let d = new Date().getTime();
	const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		const r = (d + Math.random() * 16) % 16 | 0;
		d = Math.floor(d / 16);
		return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
	});
	return uuid;
}

export const makeCorrelationId = (): string => {
	return generateUUID();
};

export const MyHttpClient: Readonly<IMyHttpClient> = {
	del,
	get,
	post,
	put,
	patch,
};
