import React, { Fragment } from 'react';
import { ContentTitle } from '../../sharedComponents/ContentBox';
import { RouteComponentProps } from 'react-router-dom';
import { AdvertisementUploader } from './AdvertisementUploader';
import { h3BottomMargin } from '../../sharedStyles/boxes';
import { IAccessTokenDecoded } from '../../services/userApis/id_server_service';

export interface IOverviewPageProps extends RouteComponentProps<{ name: string }> {
	unDecodedIDMToken: string;
	decodedIDMToken: IAccessTokenDecoded;
}

const MARKETING_ADMINISTRATOR_ROLE = 'MarketingAdministrator';

export const AdvertisementUploaderWrapper = (props: IOverviewPageProps) => {
	const noAccessMessage = 'To gain access to this page, please submit a request to the IDM team.';
	const hasCorrectRole = props.decodedIDMToken.role && props.decodedIDMToken.role.includes(MARKETING_ADMINISTRATOR_ROLE);

	if (!hasCorrectRole) {
		return <Fragment>{noAccessMessage}</Fragment>;
	} else {
		return (
			<Fragment>
				<ContentTitle title={'Frontline Marketing Tools'} />
				<h3 className={h3BottomMargin}>Login Page Advertisement Uploader</h3>
				<AdvertisementUploader bearerTokenStr={props.unDecodedIDMToken} />
			</Fragment>
		);
	}
};
