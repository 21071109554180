import { Seq } from 'immutable';
import { LocalDate, LocalDateTime, ZonedDateTime, DateTimeFormatter } from 'js-joda';
import { myLogger } from './myLogger';

export const stringifyAllJodaValues = (input: Readonly<Record<string, unknown>>): Record<string, unknown> => {
	const newObject = Seq(input)
		.map((value, key) => {
			if (value instanceof LocalDate) {
				myLogger.debug(`Converting the value of param named ${key} because LocalDate is not directly serialize-able by Axios`);
				return value.toString();
			}
			if (value instanceof LocalDateTime) {
				myLogger.debug(`Converting the value of param named ${key} because LocalDateTime is not directly serialize-able by Axios`);
				return value.toString();
			}
			if (value instanceof ZonedDateTime) {
				myLogger.debug(`Converting the value of param named ${key} because ZonedDateTime is not directly serialize-able by Axios`);
				return value.format(DateTimeFormatter.ISO_INSTANT);
			}
			return value;
		})
		.toObject();

	return newObject;
};
