import Axios from 'axios';
import { envVars } from '../../agnosticUtilities/reactEnvVars';
import { defaultErrorHandler } from '../../agnosticUtilities/myHttpErrorTranslators';
import { myLogger } from '../../agnosticUtilities/myLogger';

export interface IAccessTokenDecoded {
	/**
	 * Use sub not flid due to sso - see idm
	 */
	flid?: string;
	sub: string;
	abs_time_orguser_stage?: string;
	abs_time_campususer_stage?: string;
	abs_time_employee_stage?: string;
	abs_time_orguser?: string;
	abs_time_campususer?: string;
	abs_time_employee?: string;
	employee?: string;
	organizationid?: string | string[];
	role?: string | string[];
}

/**
 * Decode an access token
 */
export const decodeAccessToken = async (accessToken: string, correlationId: string): Promise<IAccessTokenDecoded> => {
	myLogger.trace(`About to hit the accesstokenvalidation endpoint with accessToken ${accessToken} and correlationId of ${correlationId}`);
	const urlMinusBase = '/connect/accesstokenvalidation';
	const response = await Axios.get<IAccessTokenDecoded>(`${envVars.get('ID_GATEWAY_URL')}${urlMinusBase}`, {
		params: {
			token: accessToken,
		},
	}).catch(err => {
		// tslint:disable-next-line:no-unsafe-any // Note: tslint has a longstanding bug regarding err being considered any
		return defaultErrorHandler(err, {
			// You don't normally have to do this since myHttpClient does this in other places, but here we don't use that because we need to go raw
			correlationId,
			serviceName: 'decodeAccessToken',
			url: urlMinusBase,
		});
	});
	myLogger.trace(response.data);
	return response.data;
};
