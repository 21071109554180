import React from 'react';
import { IFlIdmUserManager } from '../helpers/identity';
import { myLogger } from '../agnosticUtilities/myLogger';

interface IAuthCallbackPageProps {
	customUserManager: IFlIdmUserManager;
}

interface IAuthCallbackState {
	isInErrorState: boolean;
}

export class AuthCallbackPage extends React.Component<IAuthCallbackPageProps, IAuthCallbackState> {
	public constructor(props: IAuthCallbackPageProps) {
		super(props);
		this.state = {
			isInErrorState: false,
		};
	}

	public componentDidMount = async () => {
		try {
			const user = await this.props.customUserManager.signinRedirectCallback();
			myLogger.debug(`We got this user ${JSON.stringify(user)}`);
			window.history.replaceState({}, window.document.title, window.location.origin);
			const mainReactRoutePage = '/';
			window.location.href = mainReactRoutePage;
		} catch (err) {
			this.setState({
				isInErrorState: true,
			});
			myLogger.error(err);
		}
	};

	public render() {
		if (this.state.isInErrorState) {
			return <div>{"We apologize, but we're having an issue at the moment. Please reach out to customer support."}</div>;
		}
		return <h1 style={{ display: 'none' }}>Loading...</h1>;
	}
}
