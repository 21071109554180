// Taken from the company's color palette https://github.com/FrontlineEducation/SuperSuit-Plugin-FluidStyles/blob/master/lib/fl-fluid/colors.styl

export const flColors = {
	white: '#FFFFFF',
	black: '#000000',

	almostWhite: '#FCFCFD',

	// FLUID Color Palette
	brandPrimary: '#733878',
	brandPrimaryLt: '#B676C1',
	brandPrimaryDk: '#38264D',
	gray1: '#ECF1F5',
	gray2: '#DBE0E5',
	gray3: '#C1C8D1',
	gray: '#C1C8D1',
	gray6: '#606975',
	gray7: '#505861',
	gray8: '#303A45',
	gray9: '#1D2329',

	// state colors - used for buttons, user feedback etc.
	clrStd: '#e0e7f1',
	clrPri: '#1161B2',
	clrPos: '#007846',
	clrNeg: '#C32B1C',
	clrWarn: '#DB6B00',
};
