import { css, cx } from 'emotion';

export const linedBoxCss = css({
	borderColor: '#D5DFED',
	borderStyle: 'solid',
	borderWidth: '1px',
	borderRadius: '3px',
});

export const shadowAroundBoxCss = css({
	boxShadow: '0px 0px 5px 1px #d5dfed99',
});

export const paddedLinedBox = cx(
	linedBoxCss,
	css({
		padding: '8px 25px 8px 25px',
		marginBottom: '13px',
	}),
);

export const h3BottomMargin = css({
	marginBottom: 20,
});
