import React, { ReactNode } from 'react';
import { Layout, Col, Button } from 'antd';
import { css, cx } from 'emotion';
import { makeOidcUserManager, IFlIdmUserManager, removeUserOverride } from '../helpers/identity';
import { flColors } from '../sharedStyles/flColors';
import { flIcons } from './flIcons';
import produce from 'immer';
import { IFrontlineOidcUser } from '../helpers/oidcTypes';

const headerHeight = '60px';

const headerCss = css`
	background-color: ${flColors.brandPrimary};
	background-repeat: repeat-x;
	background-image: -webkit-linear-gradient(left, #38264d, ${flColors.brandPrimary});
	background-image: -moz-linear-gradient(left, #38264d, ${flColors.brandPrimary});
	background-image: -o-linear-gradient(left, #38264d, ${flColors.brandPrimary});
	background-image: -ms-linear-gradient(left, #38264d, ${flColors.brandPrimary});
	background-image: linear-gradient(to right, #38264d, ${flColors.brandPrimary});
	height: ${headerHeight};
	position: fixed;
	width: 100%;
	z-index: 1002;
	top: 0;
	left: 0;
	padding-left: 8px;
`;

const belowTheHeaderStyle = css({
	marginTop: headerHeight,
});

const titleCss = css`
	color: #fff;
	font-size: 18px;
	font-weight: 600;
	letter-spacing: -0.03em;
	height: 100%;
	padding: 0 15px;
	text-transform: capitalize;
	line-height: ${headerHeight};
	font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
`;

const userDropdownButtonCss = css({
	fontSize: '15px',
	top: '-1px',
	color: 'white',
	':hover, :focus': {
		backgroundColor: flColors.brandPrimaryDk,
		color: 'white',
	},
});

const invisibleButtonCss = css`
	overflow: visible;
	outline: 0;
	border: 0;
	background-color: transparent;
	height: 100%;
	width: 100%;
`;

interface IFrontlineHeaderProps {
	user: IFrontlineOidcUser;
}

interface IFrontlineHeaderState {
	dropdownIsOpen: boolean;
	flUserManager: IFlIdmUserManager;
}

export const doSignout = (flUserManager: IFlIdmUserManager | 'useDefault') => {
	removeUserOverride();
	const userManager = flUserManager === 'useDefault' ? makeOidcUserManager() : flUserManager;
	userManager.signoutRedirect();
};

export class FrontlineHeader extends React.Component<IFrontlineHeaderProps, IFrontlineHeaderState> {
	public constructor(props: IFrontlineHeaderProps) {
		super(props);
		this.state = {
			dropdownIsOpen: false,
			flUserManager: makeOidcUserManager(),
		};
	}

	private flipDropdown = () => {
		this.setState(
			produce(this.state, draftState => {
				// invert the dropdown internal state
				draftState.dropdownIsOpen = !draftState.dropdownIsOpen;
			}),
		);
	};

	public render() {
		const namePieces = this.props.user.profile.name ? this.props.user.profile.name.split(' ') : [];
		const firstName = namePieces[0] ? namePieces[0] : 'User';
		const lastName = namePieces[1] ? namePieces[namePieces.length - 1] : '';

		const bufferBetweenIcon = css({
			marginRight: '5px',
		});

		const userDropdownMenuCss = css`
			position: absolute;
			top: calc(100% - 2px);
			right: 0;
			min-width: 200px;
			background-color: white;
			color: ${flColors.gray7};
			font-size: 15px;
			font-weight: 500;
			text-align: center;
			box-shadow: 1px 2px 4px 0 rgba(0, 0, 0, 0.16);
		`;

		const displayNone = css`
			display: none;
		`;

		const dropdownRowStyle = css`
			background-color: ${flColors.gray1};
			margin: 5px;
			line-height: 36px;
			cursor: pointer;
			:hover, :focus: {
				background-color: ${flColors.gray2};
			}
		`;

		const cssMenuIcon = css`
			width: 21px;
			height: auto;
			vertical-align: sub;
			color: ${flColors.brandPrimary};
			font-size: 18px;
			font-weight: 600;
		`;

		return (
			<Layout.Header className={headerCss}>
				<Col span={16} className={titleCss}>
					Frontline Education
				</Col>
				<Col span={2} offset={6} className={`fl-identity-menu ${titleCss}`}>
					<Button onClick={this.flipDropdown} onTouchEnd={this.flipDropdown} className={cx(userDropdownButtonCss, invisibleButtonCss)}>
						<span className={bufferBetweenIcon}>
							{firstName} {lastName}
						</span>
						<UpOrDownCaret isOpen={this.state.dropdownIsOpen} />
					</Button>
					<div className={this.state.dropdownIsOpen ? userDropdownMenuCss : displayNone} role="menu">
						<div className={`fl-identity-menu-logout-btn ${dropdownRowStyle}`} role="button" onClick={() => doSignout(this.state.flUserManager)}>
							<flIcons.faPowerOffLight className={cssMenuIcon} /> Sign Out
						</div>
					</div>
				</Col>
			</Layout.Header>
		);
	}
}

const UpOrDownCaret = (props: { isOpen: boolean }) => {
	return props.isOpen ? <flIcons.faAngleUpRegular /> : <flIcons.faAngleDownRegular />;
};

export class BelowFrontlineHeader extends React.Component<{
	children: ReactNode;
}> {
	public render() {
		return <Layout className={belowTheHeaderStyle}>{this.props.children}</Layout>;
	}
}
