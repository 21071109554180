import { FontAwesomeIcon, Props } from '@fortawesome/react-fontawesome';
import { faAngleDown as faAngleDownRegular, faAngleUp as faAngleUpRegular } from '@fortawesome/free-solid-svg-icons';
import { faPowerOff as faPowerOffLight, faHome as faHomeLight, faLock as faLockLight, faCalendarAlt as faCalendarAltLight } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { Omit } from 'antd/lib/_util/type';

type FontAwesomeIconProps = Props;

type FontAwesomePropsMinusIconName = Omit<FontAwesomeIconProps, 'icon'>;

const prepFlIcon = (iconName: FontAwesomeIconProps) => {
	// eslint-disable-next-line react/display-name
	return (props: FontAwesomePropsMinusIconName) => {
		return <FontAwesomeIcon icon={iconName.icon} {...props} />;
	};
};

export const flIcons = {
	faAngleDownRegular: prepFlIcon({ icon: faAngleDownRegular }),
	faAngleUpRegular: prepFlIcon({ icon: faAngleUpRegular }),
	faPowerOffLight: prepFlIcon({ icon: faPowerOffLight }),
	faCalendarAlt: prepFlIcon({ icon: faCalendarAltLight }),
	faHomeLight: prepFlIcon({ icon: faHomeLight }),
	faLockLight: prepFlIcon({ icon: faLockLight }),
};

// export const
