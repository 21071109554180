/**
 * Must NOT be an object with functions on it
 * @param input the object you would like to have cloned
 */
export const cheapClone = <T extends object>(input: T): T => {
	if (Array.isArray(input)) {
		input.forEach(elem => {
			throwIfAnyPropIsFunction(elem as Record<string, unknown>);
		});
	}
	return JSON.parse(JSON.stringify(input));
};

function throwIfAnyPropIsFunction(input: Record<string, unknown>) {
	Object.keys(input).forEach(key => {
		const value = input[key];

		if (typeof value === 'function') {
			throw new Error(`Did not expect an object with functions. However, a function called ${key} was found.`);
		}
	});
}
