import React, { ReactNode } from 'react';
import { flColors } from './flColors';
import { css, cx } from 'emotion';

/**
 * Please try to add things sparingly here. Most styles should be wedded to their component
 */
export class FrontlineGlobalStylesCmp extends React.Component<{
	children: ReactNode;
}> {
	public render() {
		const globalStyleCss = css`
			display: inline;
			h2 {
				font-size: 33px;
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				color: #5b636c;
				font-weight: normal;
			}

			h3 {
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-size: 18px;
				font-weight: 600;
				color: #5b636c;
			}

			h4 {
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-size: 15px;
				font-weight: 600;
				color: #5b636c;
			}
			h5 {
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-size: 11px;
			}
			h6 {
				font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
				font-size: 15px;
				color: #5b636c;
			}
			.ant-form label {
				font-size: 11px;
				color: '#5b6770';
				font-weight: 600;
			}
			.ant-form-item-label {
				line-height: 20px;
			}

			main {
				margin-left: 30px;
			}

			main,
			.ant-layout-content,
			main.ant-layout-content {
				background-color: ${flColors.almostWhite};
			}
		`;
		return <div className={cx(globalStyleCss, 'frontline-global-styles-cmp')}>{this.props.children}</div>;
	}
}
