import * as logLevelLogger from 'loglevel';
import { envVars } from './reactEnvVars';

function isAValidLogLevel(hopefullyALevel: string | number): hopefullyALevel is logLevelLogger.LogLevelDesc {
	const levelKeysToChooseFrom = Object.keys(logLevelLogger.levels);
	if (typeof hopefullyALevel === 'number') {
		throw new Error(`Got ${hopefullyALevel} for LOG_LEVEL. Please use one of the following values instead: ${levelKeysToChooseFrom.join(', ')}`);
	}
	if (levelKeysToChooseFrom.includes(hopefullyALevel)) {
		return true;
	}
	return false;
}

const nonDefaultLogLevel = envVars.getOptional('LOG_LEVEL');
let logLevelStrict: logLevelLogger.LogLevelDesc;
if (nonDefaultLogLevel && isAValidLogLevel(nonDefaultLogLevel)) {
	logLevelStrict = nonDefaultLogLevel;
} else {
	logLevelStrict = 'trace';
}
logLevelLogger.setLevel(logLevelStrict);

export const myLoggerLevel = logLevelStrict;

export const myLogger = logLevelLogger;
