import { css } from 'emotion';

export const centerizedSvg = css`
	display: block;
	margin: auto;
`;

export const horizontalCentered = css`
	text-align: center;
`;
/**
 * This won't do what you want unless it's inside of a position:relative element
 */
export const bottomAbsoluteInRelative = css`
	position: absolute;
	bottom: 0;
`;

/**
 * This won't do what you want unless it's inside of a position:relative element
 */
export const centerBottomAbsoluteInRelative = css`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
`;
