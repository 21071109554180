import React from 'react';
import { css, cx } from 'emotion';
import { flColors } from '../sharedStyles/flColors';
import { centerizedSvg } from '../sharedStyles/positionStyles';

interface IFrontlineLogoProps extends React.SVGProps<SVGSVGElement> {
	centerHorizontally?: boolean;
}

export const FrontlineLogo = (props: IFrontlineLogoProps) => {
	const defaultStyle = css`
		fill: ${props.height || flColors.brandPrimary};
		height: ${props.height || 'auto'};
		width: ${props.width || 100}px;
	`;

	const classNamesToUse = [defaultStyle];

	if (props.centerHorizontally) {
		classNamesToUse.push(centerizedSvg);
	}
	// Since centerHorizontally is not a valid prop for a React component, we'll remove it
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { centerHorizontally: aVarToRemove, ...cleanedUpProps } = props;

	const logoStyle = cx(...classNamesToUse);

	return (
		<svg className={logoStyle} {...cleanedUpProps} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 75 20">
			<path d="M14.958 0h-7.802c-3.952 0-7.156 3.204-7.156 7.156v6.747c0 3.367 2.73 6.098 6.098 6.098v0c2.042 0 3.986-0.871 5.345-2.393l7.597-8.51c3.136-3.522 0.635-9.097-4.081-9.097zM6.807 16.381h-1.676c-0.793 0-1.437-0.643-1.437-1.437s0.643-1.437 1.437-1.437h1.676c0.793 0 1.437 0.643 1.437 1.437s-0.643 1.437-1.437 1.437zM15.513 5.998h-8.337c-0.649 0-1.178 0.528-1.178 1.178v0.457c0 0.649 0.528 1.177 1.178 1.177h4.743c0.793 0 1.437 0.643 1.437 1.437s-0.643 1.437-1.437 1.437h-4.743c-2.234 0-4.051-1.817-4.051-4.051v-0.457c0-2.234 1.817-4.051 4.051-4.051h8.337c0.793 0 1.437 0.643 1.437 1.437s-0.643 1.438-1.437 1.438z" />
			<path d="M24.229 4.159c0-2.031 1.11-2.586 2.613-2.586 0.325 0 0.568 0.027 0.691 0.041 0.609 0.081 0.758 0.298 0.758 0.907v0.136c0 0.609-0.149 0.677-0.758 0.609-0.122-0.013-0.258-0.027-0.555-0.027-0.596 0-0.988 0.189-0.988 0.772v0.704h1.408c0.609 0 0.758 0.149 0.758 0.758v0.149c0 0.609-0.149 0.758-0.758 0.758h-1.408v4.346c0 0.609-0.149 0.758-0.758 0.758h-0.243c-0.609 0-0.758-0.149-0.758-0.758v-6.566z" />
			<path d="M30.78 10.726c0 0.609-0.149 0.758-0.758 0.758h-0.257c-0.609 0-0.758-0.149-0.758-0.758v-3.709c0-1.598 0.962-2.464 2.816-2.464 0.027 0 0.094 0 0.149 0 0.542 0 0.691 0.257 0.691 0.867v0.149c0 0.609-0.135 0.691-0.691 0.65-0.027 0-0.122 0-0.149 0-0.88 0-1.043 0.325-1.043 0.934v3.574z" />
			<path d="M33.297 8.641v-1.083c0-1.936 1.138-3.006 3.249-3.006 2.221 0 3.222 1.002 3.222 3.006v1.083c0 1.936-1.11 3.006-3.222 3.006-2.22 0-3.249-1.002-3.249-3.006zM37.996 7.558c0-0.867-0.23-1.34-1.448-1.34s-1.476 0.473-1.476 1.34v1.083c0 0.867 0.258 1.34 1.476 1.34s1.448-0.473 1.448-1.34v-1.083z" />
			<path d="M42.682 10.726c0 0.609-0.149 0.758-0.758 0.758h-0.257c-0.609 0-0.758-0.149-0.758-0.758v-3.438c0-1.773 1.043-2.735 3.087-2.735 2.139 0 3.060 0.921 3.060 2.735v3.438c0 0.609-0.149 0.758-0.758 0.758h-0.257c-0.609 0-0.758-0.149-0.758-0.758v-3.438c0-0.691-0.176-1.069-1.286-1.069s-1.313 0.379-1.313 1.069v3.438z" />
			<path d="M53.816 2.765c0-0.609 0.149-0.758 0.758-0.758h0.243c0.609 0 0.758 0.149 0.758 0.758v6.282c0 0.704 0.189 0.907 0.596 0.907 0.027 0 0.041 0 0.068 0 0.352 0 0.461 0.081 0.461 0.623v0.258c0 0.542-0.108 0.691-0.461 0.758-0.068 0.013-0.122 0.027-0.406 0.027-1.151 0-2.017-0.637-2.017-2.179v-6.675z" />
			<path d="M58.526 1.627c0.582 0 1.056 0.488 1.056 1.056s-0.474 1.056-1.056 1.056-1.056-0.474-1.056-1.056 0.473-1.056 1.056-1.056zM57.646 5.473c0-0.609 0.149-0.758 0.758-0.758h0.243c0.609 0 0.758 0.149 0.758 0.758v5.253c0 0.609-0.149 0.758-0.758 0.758h-0.243c-0.609 0-0.758-0.149-0.758-0.758v-5.253z" />
			<path d="M62.502 10.726c0 0.609-0.149 0.758-0.758 0.758h-0.257c-0.609 0-0.758-0.149-0.758-0.758v-3.438c0-1.773 1.043-2.735 3.087-2.735 2.139 0 3.060 0.921 3.060 2.735v3.438c0 0.609-0.149 0.758-0.758 0.758h-0.257c-0.609 0-0.758-0.149-0.758-0.758v-3.438c0-0.691-0.176-1.069-1.286-1.069s-1.313 0.379-1.313 1.069v3.438z" />
			<path d="M74.106 8.004c-0.081 0.623-0.258 0.744-0.88 0.744h-3.439v0.203c0 0.704 0.514 1.029 1.773 1.029 0.704 0 1.381-0.135 1.638-0.189 0.596-0.122 0.718 0.041 0.718 0.65v0.149c0 0.609-0.122 0.731-0.718 0.88-0.258 0.068-0.893 0.176-1.652 0.176-2.397 0-3.533-0.867-3.533-2.965v-1.083c0-1.747 0.799-3.046 3.154-3.046 1.977 0 2.965 1.002 2.965 2.924-0.001 0.107-0.001 0.283-0.028 0.527zM69.787 7.314h2.667v-0.203c0-0.677-0.447-1.029-1.341-1.029s-1.327 0.352-1.327 1.029v0.203z" />
			<path d="M52.183 9.887c-0.136 0.027-0.474 0.081-0.948 0.081-0.514 0-0.853-0.231-0.853-0.907v-2.681h1.543c0.609 0 0.758-0.149 0.758-0.758v-0.149c0-0.609-0.149-0.758-0.758-0.758h-1.543v-1.056c0-0.569-0.136-0.704-0.704-0.704h-0.352c-0.568 0-0.704 0.135-0.704 0.704v5.388c0 1.977 1.11 2.586 2.613 2.586 0.473 0 0.772-0.041 0.948-0.081 0.596-0.122 0.744-0.352 0.744-0.961v-0.149c0.001-0.61-0.149-0.664-0.744-0.556z" />
			<path d="M27.314 16.254c-0.023 0.194-0.074 0.232-0.269 0.232h-2.256v0.224c0 0.687 0.351 0.948 1.165 0.948 0.448 0 0.852-0.097 1.016-0.142 0.187-0.053 0.239 0.008 0.239 0.194v0.067c0 0.142-0.023 0.209-0.232 0.269-0.232 0.067-0.575 0.142-1.023 0.142-1.15 0-1.726-0.441-1.726-1.561v-0.694c0-0.904 0.381-1.576 1.576-1.576 0.978 0 1.532 0.493 1.532 1.494 0.001 0.149-0.007 0.307-0.022 0.403zM24.789 16.015h2.017v-0.247c0-0.65-0.388-0.912-1.008-0.912-0.635 0-1.008 0.262-1.008 0.912v0.247z" />
			<path d="M31.341 16.627c0 1.016-0.538 1.561-1.636 1.561-1.151 0-1.636-0.546-1.636-1.561v-0.694c0-0.963 0.426-1.576 1.546-1.576 0.627 0 0.978 0.209 1.165 0.546v-1.778c0-0.194 0.045-0.239 0.239-0.239h0.082c0.194 0 0.239 0.045 0.239 0.239v3.503zM28.629 16.62c0 0.673 0.209 1.038 1.076 1.038 0.859 0 1.076-0.366 1.076-1.038v-0.694c0-0.68-0.217-1.038-1.076-1.038s-1.076 0.358-1.076 1.038v0.694z" />
			<path d="M32.694 16.792c0 0.553 0.202 0.867 1.001 0.867s0.993-0.313 0.993-0.867v-2.122c0-0.194 0.045-0.239 0.239-0.239h0.082c0.194 0 0.239 0.045 0.239 0.239v2.122c0 0.867-0.411 1.397-1.553 1.397-1.121 0-1.561-0.538-1.561-1.397v-2.122c0-0.194 0.045-0.239 0.239-0.239h0.082c0.194 0 0.239 0.045 0.239 0.239v2.122z" />
			<path d="M36.012 15.903c0-0.933 0.486-1.546 1.666-1.546 0.336 0 0.568 0.067 0.657 0.097 0.187 0.060 0.224 0.119 0.224 0.284v0.074c0 0.187-0.045 0.239-0.224 0.179-0.142-0.053-0.366-0.104-0.642-0.104-0.874 0-1.121 0.358-1.121 1.023v0.724c0 0.769 0.351 1.023 1.166 1.023 0.291 0 0.538-0.052 0.672-0.097 0.179-0.059 0.224 0 0.224 0.187v0.074c0 0.164-0.060 0.217-0.239 0.269-0.119 0.037-0.396 0.097-0.694 0.097-1.151 0-1.688-0.493-1.688-1.546v-0.74z" />
			<path d="M42.011 17.008c0 0.837-0.493 1.181-1.561 1.181-0.799 0-1.404-0.306-1.404-1.121 0-0.799 0.546-1.091 1.524-1.091h0.881v-0.448c0-0.538-0.388-0.679-0.978-0.679-0.358 0-0.702 0.052-0.822 0.082-0.179 0.045-0.239-0.008-0.239-0.187v-0.060c0-0.179 0.053-0.217 0.224-0.254 0.112-0.023 0.381-0.074 0.807-0.074 0.919 0 1.568 0.284 1.568 1.173v1.478zM41.45 16.418h-0.874c-0.649 0-0.971 0.164-0.971 0.65 0 0.508 0.403 0.635 0.837 0.635 0.628 0 1.008-0.119 1.008-0.717v-0.568z" />
			<path d="M46.001 12.915c0.202 0 0.358 0.164 0.358 0.366 0 0.194-0.157 0.358-0.358 0.358-0.209 0-0.373-0.157-0.373-0.358s0.164-0.366 0.373-0.366zM45.717 14.671c0-0.194 0.045-0.239 0.239-0.239h0.082c0.194 0 0.239 0.045 0.239 0.239v3.204c0 0.194-0.045 0.239-0.239 0.239h-0.082c-0.194 0-0.239-0.045-0.239-0.239v-3.204z" />
			<path d="M47.173 16.62v-0.694c0-0.971 0.463-1.568 1.643-1.568 1.195 0 1.628 0.582 1.628 1.568v0.694c0 0.971-0.456 1.568-1.628 1.568-1.203 0.001-1.643-0.582-1.643-1.568zM49.885 15.926c0-0.68-0.217-1.038-1.068-1.038-0.859 0-1.083 0.358-1.083 1.038v0.694c0 0.673 0.224 1.038 1.083 1.038 0.852 0 1.068-0.366 1.068-1.038v-0.694z" />
			<path d="M51.813 17.875c0 0.194-0.045 0.239-0.239 0.239h-0.082c-0.194 0-0.239-0.045-0.239-0.239v-2.122c0-0.859 0.433-1.397 1.561-1.397 1.143 0 1.553 0.531 1.553 1.397v2.122c0 0.194-0.045 0.239-0.239 0.239h-0.089c-0.194 0-0.239-0.045-0.239-0.239v-2.122c0-0.553-0.187-0.867-0.986-0.867s-1.001 0.313-1.001 0.867v2.122z" />
			<path d="M44.819 17.576c-0.134 0.037-0.411 0.074-0.665 0.074-0.411 0-0.687-0.179-0.687-0.792v-1.897h1.21c0.187 0 0.232-0.045 0.232-0.232v-0.067c0-0.187-0.045-0.232-0.232-0.232h-1.21v-0.769c0-0.164-0.038-0.202-0.202-0.202h-0.157c-0.164 0-0.202 0.037-0.202 0.202v3.197c0 0.986 0.523 1.322 1.255 1.322 0.336 0 0.531-0.045 0.65-0.074 0.194-0.053 0.232-0.127 0.232-0.269v-0.067c-0.001-0.186-0.053-0.238-0.225-0.193z" />
			<path d="M56.216 16.998c0 0.059-0.016 0.075-0.075 0.075h-0.319v0.956c0 0.068-0.016 0.084-0.084 0.084h-0.022c-0.068 0-0.084-0.016-0.084-0.084v-0.956h-0.319c-0.059 0-0.075-0.016-0.075-0.075v-0.022c0-0.059 0.016-0.075 0.075-0.075h0.828c0.059 0 0.075 0.016 0.075 0.075v0.022zM57.659 18.029c0 0.068-0.016 0.084-0.084 0.084h-0.022c-0.068 0-0.084-0.016-0.084-0.084v-0.947l-0.282 0.893c-0.022 0.066-0.053 0.1-0.128 0.1-0.072 0-0.107-0.025-0.132-0.1l-0.294-0.893v0.947c0 0.068-0.016 0.084-0.084 0.084h-0.018c-0.069 0-0.084-0.016-0.084-0.084v-0.997c0-0.091 0.038-0.132 0.122-0.132h0.066c0.113 0 0.153 0.097 0.188 0.206l0.241 0.753 0.259-0.832c0.032-0.097 0.075-0.128 0.182-0.128h0.034c0.084 0 0.122 0.041 0.122 0.132v0.997z" />
		</svg>
	);
};
