import React from 'react';
import { Button } from 'antd';
import { css, Interpolation } from 'emotion';
import { ButtonProps } from 'antd/lib/button';
import { assertUnreachable } from '../agnosticUtilities/neverCheckers';
import { flColors } from '../sharedStyles/flColors';

const buttonBackgroundGradientMixin = (color1: string, color2: string) => {
	return css`
		background-color: ${color1};
		background-repeat: repeat-x;
		background-image: linear-gradient(${color1}, ${color2});
	`;
};

const boxShadowButtonMixin = (color: string, shadowPxWidth: number) => {
	return css`
		box-shadow: inset 0 0 0 ${shadowPxWidth}px ${color};
	`;
};

const grayBase = '#f2f7fc';
const grayDarker = '#e0e7f1';
const grayDarkest = '#d0dbea';
export const standardBtnStyle = css`
	color: ${flColors.gray8};
	border: none;
	border-radius: 3px;
	${buttonBackgroundGradientMixin(grayBase, grayDarker)}
	${boxShadowButtonMixin(grayDarkest, 1)}
    transition-delay: 0;
	:hover,
	:focus {
		color: ${flColors.gray8};
		${buttonBackgroundGradientMixin(grayBase, grayDarker)}
		${boxShadowButtonMixin(grayDarkest, 3)}
	}
	:active {
		color: #fff;
		${buttonBackgroundGradientMixin(grayDarkest, grayBase)}
	}
	:disabled {
		opacity: 0.55;
		color: ${flColors.gray8};
		font-weight: 400;
		pointer-events: none;
	}
	margin-right: 0;
	min-width: 80px;
`;

const blueBtnBase = '#0f88ff';
const blueBtnDarker = '#1161b2';
const blueBtnDarkest = '#105ca9';
const blueBtnStyle = css`
	color: #fff;
	border: none;
	border-radius: 3px;
	${buttonBackgroundGradientMixin(blueBtnBase, blueBtnDarker)}
	${boxShadowButtonMixin(blueBtnDarkest, 1)}
    :hover,:focus {
		color: #fff;
		${buttonBackgroundGradientMixin(blueBtnBase, blueBtnDarker)}
		${boxShadowButtonMixin(blueBtnDarkest, 3)}
	}
	:active {
		color: #fff;
		${buttonBackgroundGradientMixin(blueBtnDarkest, blueBtnBase)}
	}
	:disabled {
		opacity: 0.55;
		color: #fff;
		font-weight: 400;
		pointer-events: none;
	}
	min-width: 80px;
`;

const negativeRedBase = '#c32b1c';
const negativeRedDarker = '#f8402f';
const negativeRedDarkest = '#af2719';
const negativeBtnStyle = css`
	color: #fff;
	border: none;
	border-radius: 3px;
	${buttonBackgroundGradientMixin(negativeRedBase, negativeRedDarker)}
	${boxShadowButtonMixin(negativeRedDarkest, 1)}
    transition-delay: 0;
	:hover,
	:focus {
		color: #fff;
		${buttonBackgroundGradientMixin(negativeRedBase, negativeRedDarker)}
		${boxShadowButtonMixin(negativeRedDarkest, 3)}
	}
	:active {
		color: #fff;
		${buttonBackgroundGradientMixin(negativeRedDarkest, negativeRedBase)}
	}
	:disabled {
		opacity: 0.55;
		color: #fff;
		font-weight: 400;
		pointer-events: none;
	}
`;

const greenBase = '#00f484';
const greenDarker = '#007846';
const greenDarkest = '#01663c';
export const positiveBtnStyle = css`
	color: #fff;
	border: none;
	border-radius: 3px;
	${buttonBackgroundGradientMixin(greenBase, greenDarker)}
	${boxShadowButtonMixin(greenDarkest, 1)}
    :hover,:focus {
		color: #fff;
		${buttonBackgroundGradientMixin(greenBase, greenDarker)}
		${boxShadowButtonMixin(greenDarkest, 2)}
	}
	:active {
		color: #fff;
		${buttonBackgroundGradientMixin(greenDarkest, greenBase)}
	}
	:disabled {
		opacity: 0.55;
		color: #fff;
		font-weight: 400;
		pointer-events: none;
	}
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Omit<T, K extends keyof any> = Pick<T, Exclude<keyof T, K>>;

export interface IFlButtonProps extends Omit<ButtonProps, 'type'> {
	/**
	 * deprecated
	 */
	type?: 'standard' | 'primary' | 'positive' | 'negative';
	emotionCss?: Interpolation;
}

/**
 * Exposing a class name that automation can use for simulating clicks
 */
export const FlButtonClassName = 'fl-react-button';

export const FlReactButton = (props: IFlButtonProps): JSX.Element => {
	// This use of the spread operator creates new objects that splits apart the props object so it can be further manipulated
	const { children: childrenProp, className: classNameProp, type: typeStyleProp, ...restOfTheProps } = props;

	let buttonCss: string;
	if (!typeStyleProp || typeStyleProp === 'standard') {
		buttonCss = standardBtnStyle;
	} else if (!typeStyleProp || typeStyleProp === 'primary') {
		buttonCss = blueBtnStyle;
	} else if (typeStyleProp === 'negative') {
		buttonCss = negativeBtnStyle;
	} else if (typeStyleProp === 'positive') {
		buttonCss = positiveBtnStyle;
	} else {
		return assertUnreachable(typeStyleProp);
	}

	// This allows users of fl-button to add a custom classname if they want to.
	const classNameConcatenatedStr = !classNameProp ? `${FlButtonClassName} ${buttonCss}` : `${FlButtonClassName} ${buttonCss} ${classNameProp}`;

	return (
		<Button className={classNameConcatenatedStr} {...restOfTheProps}>
			{childrenProp}
		</Button>
	);
};
