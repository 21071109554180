import React from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';
import { Layout, Menu, Spin, Row, Col, Modal } from 'antd';
import './App.css';
import { AdvertisementUploaderWrapper } from './routes/loginAdvertisementUploader/AdvertisementUploaderWrapper';
import { css, cx } from 'emotion';
import { ContentBox } from './sharedComponents/ContentBox';
import { fullHeight } from './sharedStyles/fullHeight';
import { FrontlineHeader, BelowFrontlineHeader, doSignout } from './sharedComponents/FrontlineHeader';
import { flSideNavStyle, FlSiderFooter } from './sharedComponents/FrontlineSideNav';
import { FrontlineGlobalStylesCmp } from './sharedStyles/GlobalStyles';
import { myLogger } from './agnosticUtilities/myLogger';
import { makeOidcUserManager, getUserOrRedirectToLogin, AUTH_CALLBACK_ROUTE_NAME, IFlIdmUserManager } from './helpers/identity';
import { flIcons } from './sharedComponents/flIcons';
import produce from 'immer';
import { IFrontlineOidcUser } from './helpers/oidcTypes';
import { AuthCallbackPage } from './routes/auth_callback';
import { NoMatchingRoute } from './routes/NoMatchingRoute';
import { redirectIfEnvUrlParamWasSet } from './agnosticUtilities/reactEnvVars';
import { decodeAccessToken, IAccessTokenDecoded } from './services/userApis/id_server_service';
import { makeCorrelationId } from './agnosticUtilities/myHttpClient';
import { horizontalCentered } from './sharedStyles/positionStyles';

const { Sider } = Layout;

const ROUTE_NAMES = {
	ROOT: '/',
	AD_UPLOAD: '/advertisement-upload',
};

interface IAppState {
	user?: IFrontlineOidcUser;
	decodedIDMToken?: IAccessTokenDecoded;
	unDecodedIDMToken?: string;
	customUserManager: IFlIdmUserManager;
	loading: boolean;
	errorPageMsg?: string;
	inAuthCallbackMode: boolean;
	siderIsCollapsed: boolean;
}

export class App extends React.Component<{}, IAppState> {
	public constructor(props: {}) {
		super(props);
		this.state = {
			loading: true,
			inAuthCallbackMode: false,
			siderIsCollapsed: true,
			customUserManager: makeOidcUserManager(),
		};
	}

	public async componentDidMount() {
		redirectIfEnvUrlParamWasSet();

		if (window.location.href.includes(AUTH_CALLBACK_ROUTE_NAME)) {
			this.setState({
				inAuthCallbackMode: true,
			});
		} else {
			const loginAttempt = await getUserOrRedirectToLogin(this.state.customUserManager);

			if (!loginAttempt.currentlyInRedirect) {
				const unDecodedIDMToken = loginAttempt.user.access_token;
				try {
					const decodedIDMToken = await decodeAccessToken(unDecodedIDMToken, makeCorrelationId());
					this.setState({
						loading: false,
						user: loginAttempt.user,
						decodedIDMToken,
						unDecodedIDMToken,
					});
				} catch (err) {
					myLogger.error(err);
					this.setState({
						loading: false,
						errorPageMsg: 'message' in err ? err.message : err.toString(),
					});
				}
			}
		}
	}

	public render = () => {
		const iconInNavStyle = css`
			margin-right: 10px;
		`;

		const createScaffolding = (definiteUser: IFrontlineOidcUser, unDecodedIDMToken: string, decodedIDMToken: IAccessTokenDecoded) => {
			const antdTheme = 'light';
			return (
				<FrontlineGlobalStylesCmp>
					<div className="App">
						<Router>
							<Layout className={cx(fullHeight)}>
								<FrontlineHeader user={definiteUser} />
								<BelowFrontlineHeader>
									<Sider
										className={cx(flSideNavStyle, fullHeight)}
										theme={antdTheme}
										breakpoint="lg"
										collapsedWidth="0"
										onBreakpoint={broken => {
											myLogger.debug(`media breakpoint was hit: ${broken}`);
											this.setState(
												produce(this.state, draftState => {
													draftState.siderIsCollapsed = broken;
												}),
											);
										}}
										onCollapse={(collapsed, type) => {
											myLogger.debug(`Sidenav collapsed: ${collapsed} Has type: ${type}`);
											this.setState(
												produce(this.state, draftState => {
													draftState.siderIsCollapsed = collapsed;
												}),
											);
										}}
									>
										<Menu theme={antdTheme} mode="inline">
											<Menu.Item key="1">
												<flIcons.faHomeLight className={iconInNavStyle} />
												<span>Ad Upload</span>
												<Link to={ROUTE_NAMES.AD_UPLOAD} />
											</Menu.Item>
										</Menu>

										<FlSiderFooter hide={this.state.siderIsCollapsed} />
									</Sider>
									<Layout>
										<ContentBox>
											<div>
												{/* This fragment is only here because ContentBox requires more than one element and we need to have Switch be the direct parent of Route, but we also needed Layout to be direct parent of ContentBox */}
											</div>
											<Switch>
												<Route
													exact
													path={ROUTE_NAMES.ROOT}
													render={routeProps => {
														return <AdvertisementUploaderWrapper decodedIDMToken={decodedIDMToken} unDecodedIDMToken={unDecodedIDMToken} {...routeProps} />;
													}}
												/>
												<Route
													path={ROUTE_NAMES.AD_UPLOAD}
													render={routeProps => {
														return <AdvertisementUploaderWrapper decodedIDMToken={decodedIDMToken} unDecodedIDMToken={unDecodedIDMToken} {...routeProps} />;
													}}
												/>
												<Route component={NoMatchingRoute} />
											</Switch>
										</ContentBox>
									</Layout>
								</BelowFrontlineHeader>
							</Layout>
						</Router>
					</div>
				</FrontlineGlobalStylesCmp>
			);
		};

		if (this.state.errorPageMsg) {
			return (
				<div className={horizontalCentered}>
					<div>An error occurred. Please try again later. If that does not resolve the issue, please reach out to the IDM team and copy the following message:</div>
					<div>{this.state.errorPageMsg}</div>
					<Modal visible={true} mask={true} maskClosable={false} closable={false} onOk={() => doSignout('useDefault')} okText="yes" cancelText="no">
						Would you like to log out to try again?
					</Modal>
				</div>
			);
		} else if (this.state.inAuthCallbackMode) {
			return <AuthCallbackPage customUserManager={this.state.customUserManager} />;
		} else if (!this.state.user || !this.state.unDecodedIDMToken || !this.state.decodedIDMToken) {
			myLogger.info('We should be in a redirect to try to load the user.');
			const approxPageHeight = 500;
			return (
				<Row type="flex" justify="center" align="middle" className={css({ height: approxPageHeight })}>
					<Col>
						<Spin className="currently-in-redirect" style={{ display: 'inline-block', verticalAlign: 'middle' }}>
							{/* Loading user information... */}
						</Spin>
					</Col>
				</Row>
			);
		} else {
			return createScaffolding(this.state.user, this.state.unDecodedIDMToken, this.state.decodedIDMToken);
		}
	};
}
