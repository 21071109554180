import React, { Fragment } from 'react';
import { ContentTitle } from '../sharedComponents/ContentBox';

export const NoMatchingRoute = () => {
	return (
		<Fragment>
			<ContentTitle title={'Not Found'} />
			{`There is no page that matches ${window.location.href}. Perhaps there is a typo in the url? Or maybe the url is old?`}
		</Fragment>
	);
};
