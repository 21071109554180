import { css, cx } from 'emotion';
import { flBackgroundSvgStyle } from '../sharedStyles/flBackgroundSvgStyle';
import { flColors } from '../sharedStyles/flColors';
import React from 'react';
import { horizontalCentered, centerBottomAbsoluteInRelative } from '../sharedStyles/positionStyles';
import { FrontlineLogo } from './flLogo';

export const flSideNavStyle = css({
	height: '100%',
	'.ant-layout-sider-children': css`
		box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5);
	`,
	'.ant-layout-sider-children::before': flBackgroundSvgStyle,
	'.ant-menu-item-selected > a::before': css`
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 3px;
		background-color: ${flColors.brandPrimary};
		z-index: 1;
	`,
	'.ant-menu-item': css`
		z-index: 10;
		font-size: 16px;
		font-weight: 400;
	`,
	'.ant-menu-item-active': css`
		background-color: ${flColors.gray1};
		border: 1px solid ${flColors.gray3};
		color: ${flColors.gray9};
	`,
	'li.ant-menu-item.ant-menu-item-selected': css`
		background-color: rgb(255, 255, 255, 0);
	`,
	'.ant-menu-inline .ant-menu-item-selected::after': css`
		background-color: rgb(255, 255, 255, 0);
		border-right: 0px solid rgb(255, 255, 255, 0);
	`,
	'.ant-menu-item-selected svg, .ant-menu-item-selected span': css`
		color: ${flColors.brandPrimary};
		font-weight: 600;
	`,
});

const copyrightSectionStyle = cx(
	horizontalCentered,
	css`
		font-size: 11px;
		margin-top: 5px;
		color: ${flColors.gray6};
		cursor: default;
	`,
);

const siderFooterStyle = cx(
	centerBottomAbsoluteInRelative,
	css`
		margin-bottom: 10px;
	`,
);

export const FlSiderFooter = (props: { hide: boolean }) => {
	if (!props.hide) {
		return (
			<a className={siderFooterStyle + ' fl-sider-footer'} href="http://frontlineeducation.com">
				<FrontlineLogo centerHorizontally={true} />
				<div className={copyrightSectionStyle}>© {new Date().getFullYear()} | All rights reserved</div>
			</a>
		);
	} else {
		return null;
	}
};
