import React, { Fragment, ChangeEvent } from 'react';
import { Form, Row, Col, Upload, Input, Icon, Tooltip, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FlReactButton, standardBtnStyle } from '../../sharedComponents/flReactButton';
import { myLogger } from '../../agnosticUtilities/myLogger';
import produce from 'immer';
import { UploadProps, RcFile } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { cheapClone } from '../../agnosticUtilities/cheapClone';
import { envVars } from '../../agnosticUtilities/reactEnvVars';
import { MyHttpClient, makeCorrelationId } from '../../agnosticUtilities/myHttpClient';
import { AxiosError } from 'axios';
import { assertUnreachable } from '../../agnosticUtilities/neverCheckers';

interface IUploadFormProps extends FormComponentProps<IInnerFormProps> {
	bearerTokenStr: string;
}

interface IInnerFormProps {
	targetUri: string;
	uploadFields: {
		file: RcFile;
		fileList: RcFile[];
	};
}

interface IAdvertisementImageMeta {
	targetUri: string;
	file: RcFile;
}

interface IUploadFormState {
	imageMetaTemp: Partial<IAdvertisementImageMeta>;
	imageMetaFinal: IAdvertisementImageMeta | null;
	displayList: UploadFile[];
}

class InnerForm extends React.Component<IUploadFormProps, IUploadFormState> {
	public constructor(props: IUploadFormProps) {
		super(props);
		this.state = {
			imageMetaTemp: {},
			imageMetaFinal: null,
			displayList: [],
		};
	}

	private hasAnyErrors = () => {
		const errors = this.props.form.getFieldsError();
		return Object.keys(errors).some(fieldName => {
			const potentialErrorArray = errors[fieldName];
			return potentialErrorArray && potentialErrorArray.length;
		});
	};

	private doSave = async () => {
		if (!this.state.imageMetaFinal || this.hasAnyErrors()) {
			this.validate();
			notification.error({ message: 'Please fill out or correct the form first' });
		} else {
			const url = `${envVars.get('ID_GATEWAY_URL')}/fileuploads/loginAd?targetUri=${encodeURIComponent(this.state.imageMetaFinal.targetUri)}`;
			const formData = new FormData();
			formData.append('image', this.state.imageMetaFinal.file);
			try {
				await MyHttpClient.post(
					{
						body: formData,
						url,
						correlationId: makeCorrelationId(),
						requestConfig: {
							headers: { 'Content-Type': 'multipart/form-data' },
						},
					},
					{
						idmBearerToken: this.props.bearerTokenStr,
					},
				);

				notification.success({
					message: 'Success',
					duration: 30, // seconds
					description: (
						<div>
							The image has been uploaded. NOTE: Changes may take up to 5 minutes to propagate to the environment. <br />
							<br />
							Filename: <strong>{this.state.imageMetaFinal.file.name}</strong> <br />
							<br />
							TargetUri: <strong>{this.state.imageMetaFinal.targetUri}</strong>
						</div>
					),
				});

				// blank out form values because Antd Upload component doesnt refresh its displayList after initial form submission.
				this.setState(
					produce(this.state, draftState => {
						draftState.imageMetaFinal = null;
						draftState.imageMetaTemp = {};
						draftState.displayList = [];
					}),
				);
			} catch (err) {
				notification.error({
					message: 'Error',
					description: this.stringifyErr(err),
				});
			}
		}
	};

	/**
	 * A highly specialized string conversion. Do not use elsewhere.
	 */
	private stringifyErr = (possiblyHttpError: string | Error | AxiosError): string => {
		if (typeof possiblyHttpError === 'string') {
			return possiblyHttpError;
		} else if ('message' in possiblyHttpError) {
			return possiblyHttpError.message;
		} else {
			return assertUnreachable(possiblyHttpError, 'unable to pluck the message from this');
		}
	};

	private validate = () => {
		this.props.form.validateFields((errorObj, fields) => {
			if (errorObj) {
				myLogger.warn('someone clicked the save and we got errors' + JSON.stringify(errorObj));
			} else {
				this.setState(
					produce(this.state, draftState => {
						draftState.imageMetaFinal = {
							file: fields.uploadFields.file,
							targetUri: fields.targetUri,
						};
					}),
				);
			}
		});
	};

	private preventAutoComplete = (event: ChangeEvent<{ autocomplete: string }>) => {
		if (event.target.autocomplete) {
			event.target.autocomplete = 'off';
		}
	};

	public render = () => {
		const { getFieldDecorator } = this.props.form;

		const uploaderProps: UploadProps = {
			onRemove: file => {
				this.setState(
					produce(this.state, draftState => {
						draftState.imageMetaTemp.file = undefined;
						draftState.displayList = [];
					}),
				);
				this.validate();
			},
			listType: 'picture',
			fileList: this.state.displayList,
			onChange: info => {
				let fileList = [...info.fileList];

				// 1. Limit the number of uploaded files
				// Only to show recent uploaded files, and old ones will be replaced by the new
				fileList = fileList.slice(-1);

				this.setState(
					produce(this.state, draftState => {
						draftState.displayList = cheapClone(fileList);
					}),
				);
				this.validate();
			},
			onPreview: () => {
				this.validate();
			},
			accept: '.jpg,.jpeg,.png',
			beforeUpload: (file, fileList) => {
				// Update the file
				this.setState(
					produce(this.state, draftState => {
						draftState.imageMetaTemp.file = file;
					}),
				);
				const shouldTryToUploadAutomatically = false;
				return shouldTryToUploadAutomatically;
			},
		};

		return (
			<Fragment>
				<Form layout="horizontal">
					<Row gutter={8}>
						<Col span={13}>
							<Tooltip title="prompt text"></Tooltip>
							<Form.Item label="Image File">
								{getFieldDecorator<IInnerFormProps>('uploadFields', {
									rules: [{ required: true, message: 'Please select the .jpeg or .png of the image' }],
								})(
									<Upload {...uploaderProps} className={standardBtnStyle}>
										<Icon type="upload" /> Select File
									</Upload>,
								)}
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col span={13}>
							<Form.Item label="URL Where The Ad Links">
								{getFieldDecorator<IInnerFormProps>('targetUri', {
									normalize: val => (typeof val === 'string' ? val.trim() : val),
									rules: [
										{
											required: true,
											message: 'Please provide a url of where the user will be sent if they click the advertisement',
										},
										{
											// eslint-disable-next-line
											pattern: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g,
											message: 'Must be a valid url that starts with http:// or https://',
										},
									],
								})(
									<Input
										placeholder="http://frontlineeducation.com/some-really-cool-marketing-page"
										onChange={this.validate}
										onBlur={this.validate}
										autoComplete="off"
										onFocus={this.preventAutoComplete}
									/>,
								)}
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<FlReactButton id="save-login-ad-upload-btn" onClick={this.doSave} type="primary">
					Save
				</FlReactButton>
			</Fragment>
		);
	};
}

export const AdvertisementUploader = Form.create<IUploadFormProps>({ name: 'advertisement-uploader-form' })(InnerForm);
