import testEnvConfig from './../environmentVariables/test.json';
import stageEnvConfig from './../environmentVariables/stage.json';
import localEnvConfig from './../environmentVariables/local.json';
import developmentEnvConfig from './../environmentVariables/development.json';
import qaEnvConfig from './../environmentVariables/qa.json';
import prodEnvConfig from './../environmentVariables/production.json';
import { isNotNullOrUndefined } from './genericTypes';

const rightSideDoesNotMatter = 'Only the key name matters because it will become the union of string literals defined as IRequiredEnvName';
export const requiredEnvVars = {
	IDM_CLIENT_ID: rightSideDoesNotMatter,
	ID_GATEWAY_URL: rightSideDoesNotMatter,
	ORG_SERVICE_URL: rightSideDoesNotMatter,
};

export type IRequiredEnvName = keyof typeof requiredEnvVars;

const environmentNameToConfig = {
	production: prodEnvConfig,
	stage: stageEnvConfig,
	development: developmentEnvConfig,
	qa: qaEnvConfig,
	local: localEnvConfig,
	test: testEnvConfig,
} as const;

function throwIfNotValidEnvironmentName(possibleName: string): EnvironmentName {
	const innocentUntilProvenGuilty = possibleName as EnvironmentName;
	if (environmentNameToConfig[innocentUntilProvenGuilty]) {
		return innocentUntilProvenGuilty;
	} else {
		throw new Error(`The value of localStorage.getItem('envConfig') that we found ("${possibleName}") is not correct. Supported options are: ${Object.keys(environmentNameToConfig).join(', ')}`);
	}
}

function throwIfNotString(input: unknown): string {
	if (typeof input !== 'string') {
		throw new TypeError(`Expected a string at runtime, but instead got ${JSON.stringify(input, null, 4)}`);
	}
	return input;
}

type EnvironmentName = keyof typeof environmentNameToConfig;

// We have to do this since UI code doesn't have a process.env but React uses WebPack to override it during the build
// eslint-disable-next-line no-var
declare var process: {
	env: Record<string, unknown>;
};

function checkIfDevOverrodeTheEnv(): EnvironmentName {
	const overridenEnv = localStorage.getItem('envConfig') || process.env.envConfig;

	if (overridenEnv) {
		const overridenVal = throwIfNotValidEnvironmentName(throwIfNotString(overridenEnv));
		// eslint-disable-next-line no-console
		console.warn(`envConfig has been overriden to be ${overridenVal}`);
		return overridenVal;
	} else {
		return throwIfNotValidEnvironmentName(throwIfNotString(process.env.NODE_ENV));
	}
}

export function redirectIfEnvUrlParamWasSet(): void {
	const urlParams = new URLSearchParams(window.location.search);
	const envFileNameFromUrl = urlParams.get('envConfig');

	if (envFileNameFromUrl) {
		try {
			throwIfNotValidEnvironmentName(envFileNameFromUrl);
			localStorage.setItem('envConfig', envFileNameFromUrl);
			urlParams.delete('envConfig');
			window.location.replace(window.location.origin + window.location.pathname + urlParams.toString());
		} catch (err) {
			localStorage.removeItem('envConfig');
			urlParams.delete('envConfig');
			alert(err + '\nPlease refresh the page to get rid of the bad data.');
			window.location.replace(window.location.origin + window.location.pathname + urlParams.toString());
		}
	}
}

function getConfigForEnvironment(envName: EnvironmentName): Record<string, unknown> {
	return environmentNameToConfig[envName];
}

function init() {
	const chosenEnvName = checkIfDevOverrodeTheEnv();
	const chosenConfig = getConfigForEnvironment(chosenEnvName);

	// Make sure that the required environment variables are actually present
	const missingKeys = Object.keys(requiredEnvVars)
		.map(aRequiredEnvKey => {
			if (!chosenConfig[aRequiredEnvKey]) {
				return aRequiredEnvKey;
			}
			return undefined;
		})
		.filter(isNotNullOrUndefined);

	if (missingKeys.length) {
		throw new Error(`While reading ${chosenEnvName}.json, we could not find the following required environment variables: ${missingKeys.join(', ')}`);
	}

	// Add conditional logging to make this easier for developers to understand where the environment variables are coming from
	//      Note: we could use myLogger's condition but that would create a circular dependency (reactEnvVars -> myLogger => reactEnvVars -> myLogger)
	if (process.env.NODE_ENV !== 'production') {
		// eslint-disable-next-line no-console
		console.info(`Initialized env vars from ${chosenEnvName}.json`);
		// eslint-disable-next-line no-console
		console.info(chosenConfig);
	}

	return {
		chosenEnvName,
		chosenConfig,
	};
}

const initializedConfig = init();

interface ISaferEnvGet {
	/**
	 * This is type-safe since the configInitiator utilizes nconf.required() to hard assert on startup
	 */
	get: (envVarName: IRequiredEnvName) => string;
}
interface IUnsafeEnvGet {
	/**
	 * Please use "get" instead of "getOptional" if this is a variable that must exist in all environments since that method requires the existence of the environment variable in all environments
	 */
	getOptional: (envVarName: string) => string | undefined | null;
}
type SaferNConf = ISaferEnvGet & IUnsafeEnvGet;

const genericGet = (envVarName: string) => {
	const configAsRecord = initializedConfig.chosenConfig as Record<string, string>;
	return configAsRecord[envVarName];
};

// Now that we've instantiated the variables into memory, lets expose nconf so devs can use those environment variables
// tslint:disable-next-line:no-unsafe-any // Here's where we annotate the stricter type
export const envVars: SaferNConf = {
	get: genericGet,
	getOptional: genericGet,
};
